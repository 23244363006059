<template>
  <v-container id="provider" fluid>
    <v-row class="mb-10">
      <v-col cols="11" class="mx-auto" v-show="pagingMessageList==''">
        <not-found :name="'No Message Found'"></not-found>
      </v-col>
      <v-col cols="11" class="mx-auto mt-8 mb-16" v-show="pagingMessageList!=''">
        <div class="text-h5 text-center my-5">Blasting Messages</div>
        <v-data-table
          :headers="headers"
          :items="pagingMessageList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          hide-default-footer
          class="elevation-3"
        >
          <template v-slot:item.status="{ item }">
            <v-chip :color="getColor(item.status)" dark>
              <span v-if="item.status == '1'" class="text-body-2"
                >Received</span
              >
              <span v-else-if="item.status != '1'" class="text-body-2"
                >Failed</span
              >
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="resend(item)"
              color="secondary"
              v-if="item.status != 1"
              depressed
              small
            >
              <v-icon small class="mr-2"> mdi-email-sync </v-icon>
              Resend
            </v-btn>
          </template>
        </v-data-table>

        <div class="text-center pt-4 mb-16">
          <v-pagination
            v-model="page"
            :length="pageCount"
            @input="choosePage"
            :total-visible="5"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="400"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          dark
          medium
          right
          fixed
          bottom
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <template>
        <v-card>
          <v-tabs v-model="tabs" background-color="primary" dark>
            <v-tab> Normal Promotion</v-tab>
            <v-tab> Birthday Promotion </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-card flat>
                <v-card-title><span></span></v-card-title>
                <v-card-text class="pa-7">
                  <v-row
                    ><v-col cols="3" class="pt-6"
                      ><span class="text-body-1 font-weight-bold"
                        >Target</span
                      ></v-col
                    >
                    <v-col cols="9">
                      <v-select
                        v-model="selectedCustomer"
                        :items="customer"
                        :item-text="getFieldText"
                        item-value="contact"
                        placeholder="Customer"
                        multiple
                        solo
                        :error-messages="selectedCustomerErrors"
                        required
                        @blur="$v.selectedCustomer.$touch()"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="toggle">
                            <v-list-item-action>
                              <v-icon
                                :color="
                                  selectedCustomer.length > 0
                                    ? 'indigo darken-4'
                                    : ''
                                "
                              >
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                Select All
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>

                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                          >
                            (+{{ selectedCustomer.length - 1 }} others)
                          </span>
                        </template>
                      </v-select>
                    </v-col>
  
                    <v-col cols="3" class="pt-6"
                      ><span class="text-body-1 font-weight-bold"
                        >Message</span
                      ></v-col
                    >
                    <v-col cols="9">
                      <v-textarea
                        solo
                        placeholder="hello"
                        v-model="normalMessage"
                        :error-messages="normalMessageErrors"
                        required
                        @blur="$v.normalMessage.$touch()"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions class="justify-center">
                  <v-btn
                    color="secondary"
                    @click="(loading = true), sendNormalMessage()"
                    class="white--text"
                    width="100"
                    :loading="loading"
                    >Send</v-btn
                  >
                  <v-btn
                    color="secondary"
                    outlined
                    @click="(dialog = false), $v.$reset()"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-title><span></span></v-card-title>
                <v-card-text class="pa-7">
                  <v-row
                    ><v-col cols="3" class="pt-6"
                      ><span class="text-body-1 font-weight-bold"
                        >Target Birthday Month</span
                      ></v-col
                    >
                    
                    <v-col cols="9">
                      <v-select
                        :items="month"
                        v-model="targetMonth"
                        placeholder="month"
                        :error-messages="targetMonthErrors"
                        required
                        @blur="$v.targetMonth.$touch()"
                        solo
                      ></v-select>
                    </v-col>

                    <v-col cols="3" class="pt-6"
                      ><span class="text-body-1 font-weight-bold"
                        >Message</span
                      ></v-col
                    >
                    <v-col cols="9">
                      <v-textarea
                        solo
                        placeholder="hello"
                        v-model="birthdayMessage"
                        :error-messages="birthdayMessageErrors"
                        required
                        @blur="$v.birthdayMessage.$touch()"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions class="justify-center">
                  <v-btn
                    color="secondary"
                    class="white--text"
                    @click="(loadings = true), sendBirthdayMessage()"
                    :loading="loadings"
                    width="100"
                    >Send</v-btn
                  >
                  <v-btn color="secondary" outlined @click="dialog = false"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template></v-dialog
    >
    <v-snackbar v-model="snackbar" color="primary" :timeout="timeout">
      {{ state }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="secondary"
          text
          icon
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { BASEURL } from "@/api/baseurl";
import NotFound from "@/components/NotFound";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Blasting",

  mixins: [validationMixin],

  components: {
    NotFound,
  },

  validations: {
    selectedCustomer: { required },
    normalMessage: { required },
    targetMonth: { required },
    birthdayMessage: { required },
  },

  data: () => ({
    domain: BASEURL,
    company_id: "",
    page: 1,
    itemsPerPage: 10,
    dialog: false,
    tabs: null,
    month: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    headers: [
      {
        text: "Message ID",
        align: "center",
        value: "message_id",
      },
      {
        text: "Message",
        sortable: false,
        value: "message",
        width: "50%",
      },

      { text: "Recipient", sortable: false, value: "recipient" },
      { text: "Date", value: "created_at" },
      { text: "Status", align: "center", value: "status" },
      { text: "Action", sortable: false, value: "actions" },
    ],

    selectedCustomer: [],
    customer: [],
    normalMessage: "",
    targetMonth: "",
    birthdayMessage: "",
    loading: false,
    loadings: false,
    snackbar: false,
    timeout: 2500,
    state: "",
    total: 0,
    pagingMessageList: [],
  }),

  computed: {
    likesAllFruit() {
      return this.selectedCustomer.length === this.customer.length;
    },
    likesSomeFruit() {
      return this.selectedCustomer.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    selectedCustomerErrors() {
      const errors = [];
      if (!this.$v.selectedCustomer.$dirty) return errors;
      !this.$v.selectedCustomer.required &&
        errors.push("Please selected at least one customer to send");
      return errors;
    },

    normalMessageErrors() {
      const errors = [];
      if (!this.$v.normalMessage.$dirty) return errors;
      !this.$v.normalMessage.required && errors.push("Message cannot be blank");
      return errors;
    },

    targetMonthErrors() {
      const errors = [];
      if (!this.$v.targetMonth.$dirty) return errors;
      !this.$v.targetMonth.required &&
        errors.push("Please selected an customer birthday month to send");
      return errors;
    },

    birthdayMessageErrors() {
      const errors = [];
      if (!this.$v.birthdayMessage.$dirty) return errors;
      !this.$v.birthdayMessage.required &&
        errors.push("Message cannot be blank");
      return errors;
    },

    pageCount: {
      get() {
        return this.total;
      },
      set(newName) {
        return newName;
      },
    },
  },
  created() {
    var data = JSON.parse(this.getCookie("login"));
    this.company_id = data.company_id;
    this.getAllCustomer();
    this.getPagingMessageList(this.page, this.company_id);
  },
  watch: {},

  methods: {
    getFieldText(item) {
      return `${item.name} - ${item.contact}`;
    },
    // get cookies
    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    // check status and show color
    getColor(status) {
      if (status == "1") return "green";
      else return "red";
    },
    // get the selected customer in array
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedCustomer = [];
        } else {
          const array = [];
          for (let i = 0; i < this.customer.length; i++) {
            array.push(this.customer[i].contact);
          }

          this.selectedCustomer = array;
        }
      });
    },

    //get customer
    getAllCustomer() {
      const params = new URLSearchParams();
      params.append("getAllCustomer", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/customer/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.customer = response.data.customer;
            console.log(this.customer);
          } else {
            console.log("no customer found on this company");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // send normal message
    sendNormalMessage() {
      this.$v.selectedCustomer.$touch();
      this.$v.normalMessage.$touch();

      if (
        !this.$v.selectedCustomer.$invalid &&
        !this.$v.normalMessage.$invalid
      ) {
        const params = new URLSearchParams();
        params.append("sendNormalMessage", "done");
        params.append("target", JSON.stringify(this.selectedCustomer));
        params.append("message", this.normalMessage);
        params.append("company_id", this.company_id);

        axios({
          method: "post",
          url: this.domain + "/blasting/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.loading = false;
              this.dialog = false;
              this.snackbar = true;
              this.state = "Successfully send";
              this.selectedCustomer = "";
              this.normalMessage = "";
              this.$v.$reset();
              this.getAllMessage();
            } else {
              console.log("Sendfail");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.loading = false;
      }
    },
    //resend message
    resend(item) {
      const params = new URLSearchParams();
      params.append("resend", "done");
      params.append("message_id", item.message_id);

      axios({
        method: "post",
        url: this.domain + "/blasting/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.snackbar = true;
            this.state = "Resend Successfull";
            this.getAllMessage();
          } else if (response.data.status == "2") {
            this.snackbar = true;
            this.state = "update status failed but send success";
          } else if (response.data.status == "3") {
            this.snackbar = true;
            this.state = "send failed. Please resend later";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //send birthday message

    sendBirthdayMessage() {
      this.$v.targetMonth.$touch();
      this.$v.birthdayMessage.$touch();

      if (!this.$v.targetMonth.$invalid && !this.$v.birthdayMessage.$invalid) {
        const params = new URLSearchParams();
        params.append("sendBirthdayMessage", "done");
        params.append("targetMonth", this.targetMonth);
        params.append("message", this.birthdayMessage);
        params.append("company_id", this.company_id);

        axios({
          method: "post",
          url: this.domain + "/blasting/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.loadings = false;
              this.dialog = false;
              this.snackbar = true;
              this.state = "Successfully send";
              this.targetMonth = "";
              this.birthdayMessage = "";
              this.$v.$reset();
              this.getAllMessage();
            } else {
              console.log("something went wrong ");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.loadings = false;
      }
    },
    // --get paging message list-- paging
    getPagingMessageList(page, company_id) {
      const params = new URLSearchParams();
      params.append("getPagingMessage", "done");
      params.append("page", page);
      params.append("company_id", company_id);
      axios({
        method: "post",
        url: this.domain + "/blasting/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.pagingMessageList = response.data.message;
            this.total = response.data.number_of_page;
          } else {
            console.log("no message paging list found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // choose page to load pickup
    choosePage() {
      this.getPagingMessageList(this.page, this.company_id);
    },
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-weight: bold;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: bolder;
}
</style>
